import React from "react"
import {
  AssignmentInd,
  CalendarToday,
  MultilineChart,
  Warning,
} from "styled-icons/material"

const Steps = () => {
  const items = [
    {
      title: "Enrolment",
      description:
        "Integrate with Human Resource or Patient Administration System or upload profiles via CSV file.",
      icon: <AssignmentInd size={48} />,
    },
    {
      title: "Automatic Scheduling",
      description:
        "Personalized link for each individual is sent based on set schedule (eg. everyday, every week or every month).",
      icon: <CalendarToday size={48} />,
    },
    {
      title: "Assessment",
      description:
        "Individuals take assessments remotely. Identified risks trigger critical alerts and are escalated immediately.",
      icon: <Warning size={48} />,
    },
    {
      title: "Monitoring and Action",
      description:
        "Deidentified and aggregate data is shared widely to support decision making and used to track the impact of preventative measures.  Live reporting dashboards can be used for effective planning and workforce management.",
      icon: <MultilineChart size={48} />,
    },
  ]
  return (
    <section id="audience-section" className="audience-section py-5">
      <div className="container">
        <h2 className="section-heading text-center mb-5">
          The Ongoing Process of Health Monitoring
        </h2>
        <p className="section-intro single-col-max mx-auto">
          Designed by globally recognised senior health and informatives
          experts, ZEDOC excels at user engagement, consistently getting
          high-response rates via low-friction mobile-first user interfaces that
          continuously adapt and learn from user behaviour, in addition patented
          machine learning techniques reduce question burden while maintaining
          the overall data validity.
        </p>
        <p className="section-intro single-col-max mx-auto">
          ZEDOC is highly configurable, using drag & drop style form builder and
          project wizard with a library of more than 1000 validated assessments.
        </p>
        <p className="section-intro single-col-max mx-auto mb-5">
          Interoperable by design, following leading industry standards (e.g.
          FHIR), integration with other information systems is quick and allows
          you to automate and massively scale implementations. This also enables
          external data linkages that enriches data collection, allowing you to
          create multiple layers of analysis of health status delivered in real
          time dashboards.
        </p>
        <div className="audience mx-auto">
          {items.map(({ title, description, icon }, i) => (
            <div className="item media">
              <div className="item-icon mr-3">{icon}</div>
              <div className="media-body">
                <h4 className="item-title">
                  Step {i + 1}. {title}
                </h4>
                <div className="item-desc">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Steps
