import React from "react"

const About = () => (
  <section
    id="about-section"
    className="author-section section theme-bg-primary py-5"
  >
    <div className="container py-3">
      <h2 className="section-heading text-center text-white mb-5">
        About The Clinician
      </h2>
      <div className="author-bio single-col-max mx-auto">
        <p>
          The Clinician brings unparalleled experience in healthcare and
          implementation of health information systems across a broad range of
          clinical and administrative domains. Led by clinicians, our team have
          excellent track record in healthcare and health informatics, bringing
          different and complementary skills in software and UX design,
          interoperability and standards-based integration with hospital
          systems, clinical registries, data science and secondary data use,
          health economics and health research.
        </p>
        <p>
          The deep domain expertise, combined with our passion to improve
          healthcare, is wholly reflected in the company's value-based care
          approach and this platform which addresses the global need for
          outbreak management.
        </p>
      </div>
    </div>
  </section>
)

export default About
