import { Bluetooth } from "@styled-icons/fa-brands/Bluetooth"
import React from "react"
import { Language, Network } from "styled-icons/entypo"
import {
  ChartPie,
  HourglassHalf,
  UserCog,
  Users,
  UserShield,
} from "styled-icons/fa-solid"

const Benefits = () => {
  const items = [
    {
      title: "REACHING OUT TO INDIVIDUALS",
      description:
        "Communication is made via email/SMS or Whatsapp. ZEDOC App is launched instantly by clicking time-limited secure links without the need to download and install. Full multimedia support and real time push notifications are supported like a native App.",
      icon: <Network size={48} />,
    },
    {
      title: "REAL TIME MONITORING & ROLL CALL",
      description: "Monitor health, attendance and locations.",
      icon: <Users size={48} />,
    },
    {
      title: "MULTI-LANGUAGES",
      description:
        "Localised content is automatically delivered in the default language of the users device.",
      icon: <Language size={48} />,
    },
    {
      title: "DATA ANALYSIS",
      description:
        "Live reporting dashboards for actionable insights and predictive analytics for work and resource planning.",
      icon: <ChartPie size={48} />,
    },
    {
      title: "RISK MANAGEMENT",
      description:
        "Rule based triaging and alerting when responses fall outside set parameters.",
      icon: <HourglassHalf size={48} />,
    },
    {
      title: "WORKFORCE MANAGEMENT",
      description:
        "Intelligent workforce management capability at times where and when resources are scarce and coordination and human intervention is needed for relief.",
      icon: <UserCog size={48} />,
    },
    {
      title: "SECURITY COMPLIANCE",
      description:
        "Meeting the most stringent privacy and security regulations, ZEDOC uses the fire and forget approach, purging all personally identifiable information immediately after purposeful use.",
      icon: <UserShield size={48} />,
    },
    {
      title: "BLUETOOTH DEVICES SUPPORT",
      description:
        "Seamlessly collect objective sensor data such as body temperature from a wide range of supported bluetooth devices.",
      icon: <Bluetooth size={48} />,
    },
  ]

  return (
    <section
      id="benefits-section"
      className="benefits-section theme-bg-light-gradient py-5"
    >
      <div className="container">
        <h2 className="section-heading text-center mb-5">
          How will ZEDOC help and support your organisation?
        </h2>
        <p className="section-intro single-col-max mx-auto text-center mb-5">
          ZEDOC is an AI driven cloud based platform for real-time symptom
          monitoring, analysis and risk prediction which are critical during
          disease outbreaks such as COVID-19. The ZEDOC platform automatically
          contacts individuals at specific time points via email or SMS to check
          their health status and collects temperature readings via the mobile
          App or Web. The ZEDOC App is readily available for use without needing
          to download and install from an App Store. Thanks to the low friction
          design and state-of-the-art adaptive survey delivery, responses can be
          collected quickly and users keep engaged over time. Actionable
          information is then made available at various levels via real time and
          interactive dashboards providing instant insights for effective
          management of risks and identified hazards. ZEDOC sends out targeted
          alerts when certain risk parameters fall outside a set range and
          automatically dispatch's additional assessments when needed.
        </p>
        <div className="row text-center">
          {items.map(({ title, description, icon }) => (
            <div className="item col-12 col-md-6 col-lg-4">
              <div className="item-inner p-3 p-lg-4">
                <div className="item-header mb-3">
                  <div className="item-icon mb-2">{icon}</div>
                  <h3 className="item-heading">{title}</h3>
                </div>
                <div className="item-desc">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Benefits
