import React from "react"
import About from "../components/About"
import Benefits from "../components/Benefits"
import Form from "../components/Form"
import Hero from "../components/Hero"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Steps from "../components/Steps"
import "../sass/theme.scss"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Benefits />
    <Steps />
    <Form />
    <About />
  </Layout>
)

export default IndexPage
