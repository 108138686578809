import React from "react"

const Footer = () => (
  <footer>
    <div className="container-fluid py-5">
      <div class="row justify-content-center">
        <ul className="list-inline m-0">
          <li className="list-inline-item mr-5">
            <small>New Zealand</small>
            <br />
            <a href="tel:0800102647">0 800 102 647</a>
          </li>
          <li className="list-inline-item mr-5">
            <small>Singapore</small>
            <br />
            <a href="tel:+6531591168">+65 315 911 68</a>
          </li>
          <li className="list-inline-item mr-5">
            <small>Australia</small>
            <br />
            <a href="tel:+61391338986">+61 391 338 986</a>
          </li>
          <li className="list-inline-item">
            <small>Email</small>
            <br />
            <a
              href="mailto:info@theclinician.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@theclinician.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
