import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import image from "../images/hero.jpg"

const Component = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    ${`url(${image})`};
  background-size: cover;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledLine = styled.div`
  background-color: #ed6524;
`

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.svg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Component className="hero-section py-5">
        <StyledLine className="p-3">
          <h1 className="headline text-center m-0">
            {data.site.siteMetadata.title}
          </h1>
        </StyledLine>
      </Component>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center">
            <p className="section-intro" style={{ margin: 0 }}>
              {data.site.siteMetadata.description}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero
