import React, { useState } from "react"

const Form = () => {
  const [isSent, setIsSent] = useState(false)

  const handleOnSubmit = event => {
    event.preventDefault()

    const data = new FormData(event.target)
    const email = data.get("email")
    const body = email
      ? `Someone requested ZEDOC access on https://covid19.theclinician.com/ from ${data.get(
          "email"
        )}.`
      : null

    if (!body) {
      return
    }

    fetch(
      "https://8sabcjznnb.execute-api.ap-southeast-1.amazonaws.com/prod/form",
      {
        method: "POST",
        body,
      }
    ).then(() => setIsSent(true))
  }

  return (
    <section id="form-section" className="form-section py-5">
      <div className="container">
        <div className="lead-form-wrapper single-col-max mx-auto theme-bg-light rounded p-5">
          <h2 className="form-heading text-center mb-4">Request Access</h2>
          {/* <p className="form-intro text-center mb-3">Sign up to get access</p> */}
          <div className="form-wrapper">
            <form
              className="form-inline justify-content-center flex-wrap"
              onSubmit={handleOnSubmit}
            >
              <div className="form-group mr-md-2 mb-3 mb-md-0">
                <label className="sr-only" htmlFor="email">
                  Email
                </label>

                <input
                  id="email"
                  name="email"
                  className="form-control"
                  type="email"
                  placeholder="Your Email"
                />
              </div>
              <button
                className="btn btn-primary btn-submit mb-3 mb-md-0"
                type="submit"
              >
                Request
              </button>
            </form>
            {isSent && (
              <p className="section-intro text-center text-success mt-4">
                Thank you, we will be in touch shortly.
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Form
