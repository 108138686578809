import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.svg"

const Header = ({ author }) => (
  <header>
    <div className="container py-3">
      <div className="row justify-content-space-between">
        <div className="col">
          <div className="site-logo">
            <a className="navbar-brand" href="/" target="_blank">
              <img
                className="logo-icon"
                src={logo}
                alt="logo"
                style={{ height: 60 }}
              />
              {/* <span className="logo-text" style={{ verticalAlign: "middle" }}>
                {author}
              </span> */}
            </a>
          </div>
        </div>
        <div className="col align-self-center text-right">
          <a className="btn" href="#about-section">
            Have questions?
          </a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  author: PropTypes.string,
}

Header.defaultProps = {
  author: ``,
}

export default Header
